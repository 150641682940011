import keyMirror from 'keymirror'

function generateActionTypes(actions) {
  const typeMap = {}
  for (const i in actions) {
    const action = actions[i]
    typeMap[`${action}_REQUEST`] = null
    typeMap[`${action}_SUCCESS`] = null
    typeMap[`${action}_FAILURE`] = null
  }

  return keyMirror(typeMap)
}

export default generateActionTypes([
  'INIT_API',
  'FETCH_MY_APPS',
  'FETCH_APP_DETAIL',
  'FETCH_APP_QUICK_SETTING',
  'SILENTLY_FETCH_APP_QUICK_SETTING',
  'FETCH_APP_SETTINGS',
  'SILENTLY_FETCH_APP_SETTINGS',
  'UPLOAD_APP_SETTINGS',
  'SILENTLY_FETCH_APP_DETAIL',
  'FETCH_APP_ITERATION_DETAIL_SILENTLY',
  'FETCH_APP_ITERATION_DETAIL_OPENLY',
  'START_APP_JOB',
  'STOP_APP_JOB',
  'DELETE_APP',
  'SAVE_COT_OBJECT',
  'FETCH_SAVEDCOT_OBJECT',
  'DELETE_SAVEDCOT_OBJECT',
  'FETCH_PERMISSION_MATRIX',
  'APP_SANDBOX_RUN',
  'APP_QUICK_SETTINGS',
  'RESET_APP_SANDBOX_RUN',
  'SAVE_APP_SCHEDULE',
  'FETCH_TEMPLATES',
  'FETCH_RECC_TEMPLATES',
  'FETCH_WELLKNOWN',
  'FETCH_MY_PROFILE',
  'CUSTOM_USER_META',
  'SAVE_DEPLOYMENT_PROFILE',
  'DELETE_DEPLOYMENT_PROFILE',
  'FETCH_SUBSCRIPTION_USAGE',
  'DEPLOY_APP',
  'DELETE_DEPLOY_APP',
  'FETCH_ITR_SAMPLE_DATA',
  'FETCH_ITR_SAMPLE_MODEL',
  'TRIGGER_APP_UNDOCK',
  'FETCH_MY_MODELS',
  'FETCH_NEW_MY_DATASETS',
  'FETCH_VERSION_INFO',
  'FETCH_MY_DATASETS',
  'FETCH_MY_DATASETS_EMBED',
  'CREATE_FOLDER_DATASETS',
  'CREATE_GROUP_DATASETS',
  'CREATE_DATASET',
  'CREATE_TUS_DATASET',
  'ADD_ITEMS_TO_DATASET_TUS',
  'FETCH_DATASET_DETAIL',
  'FETCH_DATASET_DETAIL_EMBED',
  'FETCH_DATASET_GROUP_DETAIL',
  'SILENTLY_FETCH_DATASET_DETAIL',
  'SILENTLY_FETCH_DATASET_DETAIL_EMBED',
  'ADD_ITEMS_TO_DATASET',
  'FETCH_SETTINGS_CONTENT',
  'BUILD_APP_TEMPLATE',
  'FETCH_DATASET_ITEM_SAMPLE',
  'FETCH_DATASET_SCHEMA',
  'FETCH_MODEL_DETAIL',
  'FETCH_DATASET_ITEM_SCHEMA',
  'FETCH_DATASET_ITEM_ANNOTATION_SUMMARY',
  'DELETE_DSSET',
  'DELETE_DSFOLDER',
  'DELETE_DSITEM',
  'DELETE_DSGROUP',
  'MOVE_DSITEM_GROUP',
  'MOVE_DSFOLDER_FOLDER',
  'MOVE_DSET_FOLDER',
  'EDIT_DSET_SCHEMA',
  'NOTIFICATION_CENTER_ADD_TASK',
  'FETCH_HELP_LINKS',
  'GET_AUTO_MAPPINGS',
  'FETCH_EXPLORE_EXECUTE',
  'FETCH_EXPLORE_PREVIEW',
  'DELETE_DATASET_TUSIO',
  'DATASET_EXISTS_PRECHECK',
  'DATASET_ITEM_EXISTS_PRECHECK',
  'DATASET_DOWNLOAD_PRECHECK',
  'FETCH_USER_ACCOUNT_INFO',
  'SILENTLY_FETCH_USER_ACCOUNT_INFO',
  'SEND_INVITATION_CODE',
  'FETCH_UBER_DEPLOYMENT_STATUS',
  'SI_FE_UB_DEPLOYMENT_STATUS',
  'UBER_MANUAL_SHUTDOWN',
  'UBER_DEPLOY_APP',
  'GET_THEME',
  'GET_CURRENTCONTEXT',
  'GET_CONTAINER_METERING',
  'INVITATION_CODE_PRECHECK',
  'FETCH_PAYMENT_RECEIPTS',
  'PING_MISERVICE',
  'FETCH_MDCOUNT',
  'FETCH_SELECTED_PROJECT_INFO_STATS',
  'FETCH_USER_ROLES',
  'FETCH_USER_ACTIONS',
  'GET_USER_ROLES',
  'DELETE_USER_FROM_PROJECT',
  'DELETE_PROJECT',
  'UPDATE_PROJECT_OBJECTIVE',
  'UPDATE_USER_ROLE',
  'FETCH_USERS_ORG',
  'FETCH_ALL_USER_ROLES',
  'START_SUPERSET_SERVICE',
  'STOP_SUPERSET_SERVICE',
  'STATUS_SUPERSET_SERVICE',
  'START_MICRO_SERVICE',
  'ADD_USER_TO_PROJECT_SUPERSET',
  'DELETE_USER_FROM_SUPERSET_PROJECT',
  'CREATE_DASHBOARD_SUPERSET',
  'GET_DASHBOARD_SUPERSET',
  'DELETE_DASHBOARD_SUPERSET',
  'CREATE_CHART_SUPERSET',
  'CAYLEY_PAGE_VIEW',
  'STOP_MICRO_SERVICE',
  'FETCH_MICRO_SERVICE_META',
  'LOCK_DATASET_TYPE',
  'DELETE_DATASET_SERVICE',
  'DELETE_MI_SERVICE',
  'STATUS_MICRO_SERVICE',
  'START_DSS_SERVICE',
  'STATUS_DSS_SERVICE',
  'STATUS_MI_SERVICE',
  'FETCH_CAYLEY_DISTRIBUTION',
  'POLL_FETCH_CAYLEY_DISTRIBUTION',
  'ALL_ORG_CONFIG_RULES',
  'UPDATE_ORG_CONFIG',
  'RESET_ORG_COFIGS',
  'GET_APP_RECOMMENDATIONS',
  'GET_DATASET_SUMMARY',
  'GET_SUBSCRIPTION_CHECK',
  'GET_PROJECT_FLOWS',
  'GET_ORGPROJECT_FLOWS',
  'DELETE_PROJECT_OF_ORG',
  'FETCH_EXPLORE_INIT',
  'FETCH_EXPLORE_EXECUTE',
  'FETCH_EXPLORE_POLL',
  'FETCH_EXPLORE_SAVE',
  'FETCH_CHECKOUT_SESSION',
  'CREATE_USER_ORGANIZATION',
  'FETCH_UPLOAD_POLL',
  'FETCH_DATASET_MAX_UPLOAD',
  'FETCH_SCHEMA_VALIDATE_DS',
  'START_JUPYTER_SERVICE',
  'CREATE_A_NEW_PROJECT',
  'STOP_JUPYTER_SERVICE',
  'STATUS_JUPYTER_SERVICE',
  'START_CHAT_GENAI',
  'GET_PROJECT_GROUPS',
  'FETCH_DOWN_UBER',
  'ROLES_UPDATED',
  'GET_LIST_GROUPS',
  'GET_ORG_ENTITY_GROUPS',
  'CREATE_A_NEW_GROUP',
  'GET_GROUP_DETAILS',
  'GET_GROUP_MEMBERS',
  'DELETE_GROUP_DETAILS',
  'ADD_GROUP_ENTITY',
  'DELETE_GROUP_ENTITY',
  'REGISTER_A_NEW_USER',
  'INVITE_A_NEW_USER',
  'GET_ALL_ORG_PROJECTS',
  'COMPLETE_USER_ONBOARDING',
  'DEPLOY_GENAI_SERVICE_APP',
  'FETCH_GENAI_SERVICE_APP',
  'STOP_GENAI_SERVICE_APP',
  'BILLING_RENEWAL_CHECK',
  'UPDATE_OBJECTIVE_PROJECT'
])

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE'

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE'

// Notification
export const ADD_TOAST_NOTIFICATION = 'ADD_TOAST_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

// STATUS WINDOW
export const GET_ITEM_STATUS_LIST = 'GET_ITEM_STATUS_LIST'
export const DELETE_ITEM_STATUS_LIST = 'DELETE_ITEM_STATUS_LIST'
