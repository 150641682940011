import { lazy } from 'react'

const Projects = lazy(() => import('../Admin/projects'))
const ProjectInfo = lazy(() => import('../Admin/ProjectInfo/index.js'))
const AppDetail = lazy(() => import('../../components-examples/AppDetail/index.js'))
const MyApps = lazy(() => import('../../components-examples/MyApps'))
const MyDatasets = lazy(() => import('../../components-examples/Datasets/myDataset'))
const DatasetDetail = lazy(() => import('../../components-examples/Datasets/myDatasetDetail'))
const Invitation = lazy(() => import('../UserInvitation/InvitationProcess'))
const OrganizationsInfo = lazy(() => import('../Admin/organizations'))
const ModelVisualization = lazy(() => import('../TreeBrowser/ModelReportPage/index.js'))
const InsightsOfInterests = lazy(() => import('../Datasets/ioi/InsightsOfInterests'))
const SuperSet = lazy(() => import('../SuperSet/index'))
const EmlyNoteBook = lazy(() => import('../Jupyter/index'))
const Profile = lazy(() => import('../../Profile'))
const GroupInfo = lazy(() => import('../Organizations/groups/GroupInfo.js'))
const onBoardUser = lazy(() => import('../../../UI-components/onBoardUser.js'))
const CoreEngine = lazy(() => import('../../../UI-components/Restore/CoreEngine.js'))

export const userNFoundRoutes = [{ path: '/onboarduser', name: 'User Onboard', element: onBoardUser }]

export const routes = [
  { path: '/profile', name: 'Admin', element: Profile },
  { path: '/drestore', name: 'DevelopRestore', element: CoreEngine },
  { path: '/invitation', name: 'Invitation', element: Invitation },
  { path: '/orgInstance', name: 'Org Instance', element: CoreEngine },
  { path: '/organizations', name: 'Organizations', element: OrganizationsInfo },
  { path: '/organizations/:tabId', name: 'OrganizationsTabs', element: OrganizationsInfo },

  { path: '/projects', name: 'Projects', element: Projects },
  { path: '/:organizationId/:projectId/projects/:id', name: 'ProjectInfo', element: ProjectInfo },

  { path: '/:organizationId/:projectId/apps', name: 'MyApps', element: MyApps },
  { path: '/apps/:id', name: 'MyAppsInfo', element: AppDetail },

  { path: '/:organizationId/:projectId/insights/:id', name: 'InsightsOfInterests', element: InsightsOfInterests },

  { path: '/:organizationId/:projectId/datasets', name: 'Datasets', element: MyDatasets },
  { path: '/:organizationId/:projectId/datasets/:id', name: 'DatasetId', element: MyDatasets },
  { path: '/:organizationId/:projectId/datasets/detail/:id', name: 'DatasetDetailID', element: DatasetDetail },

  {
    path: '/:organizationId/:projectId/datasets/detail/:id/groups/:groupid',
    name: 'DatasetDetail',
    element: DatasetDetail
  },
  {
    path: '/:organizationId/:projectId/datasets/detail/:id/groups',
    name: 'DatasetDetailGroup',
    element: DatasetDetail
  },

  { path: '/model-visualization/:id', name: 'ModelVisualization', element: ModelVisualization },
  {
    path: '/:organizationId/:projectId/emlyViz',
    name: 'EmlyViz',
    element: SuperSet
  },
  {
    path: '/:organizationId/:projectId/emlyNoteBook',
    name: 'EmlyNoteBook',
    element: EmlyNoteBook
  },

  { path: '/organizations/groups/:groupId', name: 'GroupInfo', element: GroupInfo }
]

export const visibleRoutes = [
  '/organizations',
  '/organizations/:tabId',
  '/organizations/billings',
  '/organizations/usage',
  '/organizations/groups',
  '/organizations/projects',
  '/organizations/users',
  '/organizations/policies',
  '/projects',
  '/projects/:id',
  '/invitation',
  '/profile'
]

export const mobileRoutes = cUser => [
  '/signup',
  '/projects',
  '/orgInstance',
  '/organizations',
  '/invitation',
  `/${cUser.orgId}/${cUser.spaceId}/apps`,
  `/${cUser.orgId}/${cUser.spaceId}/datasets`,
  `/${cUser.orgId}/${cUser.spaceId}/projects/${cUser.spaceId}`
]

export const publicRoutes = ['/signup']

export default routes
