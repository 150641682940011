import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PopUP from '../../api/components-examples/PopUP'
import CoreEngine from '../Restore/CoreEngine'

function RestoreUber(props) {
  const onCancel = () => {
    props.dispatch({ type: 'FETCH_DOWN_UBER_SUCCESS', payload: { status: 'HIDEUBERISDOWN' } })
  }

  const bodyContent = () => {
    return <CoreEngine onCancel={() => onCancel()} showCancel={props.showCancel} callBackfunction={props.callBackfunction} />
  }

  return (
    <>
      {props.asPopup === true ? (
        <PopUP
          width={'600px'}
          loadingCancel={true}
          title={''}
          buttonLabel={'Restore'}
          className={'res-Op-card'}
          bottomButton={false}
          bottomButtonCancel={false}
        >
          {bodyContent()}
        </PopUP>
      ) : (
        <div className="em-row em-flex" style={{ height: '-webkit-fill-available' }}>
          {bodyContent()}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  myUserAccountInfo: state.myUserAccountInfo,
  checkUberDeployment: state.checkUberDeployment,
  theme: state.theme
})

export default withRouter(connect(mapStateToProps)(RestoreUber))
