import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCurrentContextuserIdDetails, isJson } from '../../api/components-examples/Admin/AccountUtility'
import {
  getCurrentOrganizationById,
  uberRunningStates,
  uberUpStates
} from '../../api/components-examples/utils/commonUtilityFunctions'
import { addNotification, showNotification } from '../../api/redux/notificationAction'
import './index.css'
import RestoreInstance from './RestoreInstance'
import { RunningInstance } from './RunningInstance'

const CoreEngine = props => {
  const [instance, setInstance] = useState({
    title: 'Emly Core Engine Service is not active',
    description:
      "Your core engine service is currently not active. It is responsible for managing your datasets and AI models.Without this service, you won't be able to access or manage your data and models. Please click restore to activate this service.",
    size: 'NA',
    sizeDescription: "Memory limit in GB's for EMLY Core Engine Instance",
    ttl: 'Na',
    ttlDescription: 'Time in minutes to wait before shutting down  EMLY Core Engine Instance after no activity'
  })
  const [uberService, setUberService] = useState(props.checkUberDeployment)
  const [instanceType, setInstanceType] = useState('uber.container')
  const intervalRef = useRef(null)

  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  useEffect(() => {
    orgConfigurations()
  }, [])

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  useEffect(() => {
    if (props.checkUberDeployment && !props.checkUberDeployment.isLoading) {
      setUberService(props.checkUberDeployment)
    }
    const isorgInstance = props.checkUberDeployment && !props.checkUberDeployment.isLoading && props.checkUberDeployment.status ? uberUpStates.includes(props.checkUberDeployment.status) : false
    if (isorgInstance === true)
      navigateNextLocation()
  }, [props.checkUberDeployment])

  const fetchUberStatus = () => {
    const api = props.api
    const action = api.createFetchUberDeploymentRSAA(current_logged_user.orgId, false, current_logged_user.spaceId)
    Promise.resolve(props.dispatch(action)).then(result => {
      if (result.type.endsWith('SUCCESS')) {
        if (result.payload.status === 'DEPLOY_SUCCESS') {
          clearCurrentInterval()
          checkAllowedSize(current_logged_user)

          if (props.callBackfunction) {
            props.callBackfunction()
          }

          if (props &&
            props.location &&
            props.location.pathname &&
            props.location.pathname === '/orgInstance') {
            navigateNextLocation()
          }

          props.dispatch(
            addNotification(`Services for ${current_logged_user.orgIdName} are ready for Use!`, 'success', false)
          )
          props.dispatch(showNotification(true))
        }

        if (result.payload.status === 'DEPLOY_FAILED') {
          clearCurrentInterval()
          let error =
            result.payload &&
              result.payload.uberDeployment &&
              result.payload.uberDeployment.containerDeployment &&
              result.payload.uberDeployment.containerDeployment.failureCause
              ? result.payload.uberDeployment.containerDeployment.failureCause
              : '{userMessage:Error Not Found}'
          try {
            let errorUserMessage = isJson(error)
            props.dispatch(addNotification(errorUserMessage['userMessage'], 'danger', false))
          } catch (error) {
            props.dispatch(
              addNotification(
                `Failed: We didn't get resources for  ${current_logged_user.orgIdName} to start Services`,
                'danger',
                false
              )
            )
          }
          props.dispatch(showNotification(true))
        }
      } else {
        clearCurrentInterval()
      }
    })
  }

  const orgConfigurations = () => {
    const orgConfiguration =
      props.orgConfiguration && props.orgConfiguration.orgConfigurations ? props.orgConfiguration.orgConfigurations : []
    const data = orgConfiguration.map(i => {
      return {
        name: i.configurationDefaultValue.key,
        value: i.value,
        description: i.configurationDefaultValue.description
      }
    })

    const configuration = {
      size:
        data.find(i => i.name === `${instanceType}.size`) !== undefined
          ? data.find(i => i.name === `${instanceType}.size`).value
          : 0,
      sizeDescription:
        data.find(i => i.name === `${instanceType}.size`) !== undefined
          ? data.find(i => i.name === `${instanceType}.size`).description
          : 0,
      ttl:
        data.find(i => i.name === `${instanceType}.ttl`) !== undefined
          ? data.find(i => i.name === `${instanceType}.ttl`).value
          : 0,
      ttlDescription:
        data.find(i => i.name === `${instanceType}.ttl`) !== undefined
          ? data.find(i => i.name === `${instanceType}.ttl`).description
          : 0
    }

    setInstance(prevState => ({
      ...prevState,
      ...configuration
    }))
  }

  const checkAllowedSize = current_logged_user => {
    const api = props.api
    if (api === null) return
    if (current_logged_user.spaceId !== undefined || current_logged_user.spaceId !== null)
      props.dispatch(api.createDatasetUploadMaxSizeRSAA(current_logged_user))
  }

  const restoreInstance = () => {
    //Add Requesting State
    props.dispatch({ type: 'FETCH_DOWN_UBER_SUCCESS', payload: { status: 'HIDEUBERISDOWN' } })
    props.dispatch({ type: 'FETCH_UBER_DEPLOYMENT_STATUS_SUCCESS', payload: { status: 'REQUESTING' } })

    const currentOrganization = getCurrentOrganizationById(props.myUserAccountInfo, current_logged_user.orgId)
    const params = { projectId: current_logged_user.spaceId }

    //this.setState({ enableLoader: true })
    const api = props.api
    const action = api.createUberDeploymentRSAA(
      {
        body: JSON.stringify({
          deploymentInfo: {
            services: []
          },
          orgInfo: {
            orgName: currentOrganization.name
          }
        })
      },
      current_logged_user.orgId,
      params
    )
    Promise.resolve(props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        setTimeout(() => {
          fetchUberStatus()
        }, 3000)
      } else {
        props.dispatch({ type: 'FETCH_UBER_DEPLOYMENT_STATUS_SUCCESS', payload: { status: 'NOT_FOUND' } })
        props.dispatch(addNotification(response.payload.response.message, 'error', false))
        props.dispatch(showNotification(true))
      }
    })
  }

  useEffect(() => {
    if (props.checkUberDeployment && uberRunningStates.includes(props.checkUberDeployment.status)) {
      clearInterval(intervalRef.current)

      intervalRef.current = setInterval(() => {
        fetchUberStatus()
      }, 10000)
    }
    return () => clearCurrentInterval()
  }, [props.checkUberDeployment])


  useEffect(() => {
    return () => clearCurrentInterval()
  }, [])

  const navigateNextLocation = () => {
    let paths = '/projects'
    if (current_logged_user.spaceId !== 'undefined' && props.myUserAccountInfo.rolesUser !== undefined) {
      paths = `/${current_logged_user.orgId}/${current_logged_user.spaceId}/projects/${current_logged_user.spaceId}`
    }
    const { history } = props
    history.push(paths)
  }

  return (
    <>
      <div className="rs-em-sc-wrapper">
        {uberRunningStates.includes(uberService.status) ? (
          <div className={`rs-em-sc-content ${props.showCancel === true ? 'showborder' : ''}`}>
            <RunningInstance instance={instance} uberService={uberService} instanceType={instanceType} />
          </div>
        ) : (
          <div className={`rs-em-sc-content ${props.showCancel === true ? 'showborder' : ''}`}>
            <RestoreInstance
              instance={instance}
              instanceType={instanceType}
              onCancel={props.onCancel}
              showCancel={props.showCancel}
              restoreInstance={() => restoreInstance}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default withRouter(connect(state => state)(CoreEngine))
